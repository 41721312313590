import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['buttonLabel', 'buttonIcon', 'section', 'button'];

  connect() {
    $(this.sectionTargets).hide();
    $(this.buttonTarget).on('click', this.toggle.bind(this));

    this.hidden = true;
  }

  toggle() {
    this.hidden = !this.hidden;

    $(this.sectionTargets).toggle();

    if (this.hasButtonLabelTarget) {
      $(this.buttonLabelTarget).text(this.hidden ? 'Show More' : 'Show Less');
    }

    if (this.hasButtonIconTarget) {
      $(this.buttonIconTarget).toggleClass('fa-plus');
      $(this.buttonIconTarget).toggleClass('fa-minus');
    }
  }
}
