// app/javascript/controllers/colour_set_picker_controller.js
import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['colorPickerTemplate', 'addColorButton'];

  connect() {
    // You can use this lifecycle method to initialize if needed
  }

  addColor(event) {
    event.preventDefault();

    // Clone the first color input wrapper
    const newColorInputWrapper = $(this.colorPickerTemplateTarget).clone();
    newColorInputWrapper.removeClass('hidden');
    newColorInputWrapper.removeAttr('data-colour-set-picker-target');

    // Clear the value of the cloned input field
    newColorInputWrapper.find('input').val('');
    newColorInputWrapper.find('input').prop('disabled', false);

    // Append the cloned input before the add button
    $(this.addColorButtonTarget).before(newColorInputWrapper);
  }

  removeColor(event) {
    event.preventDefault();
    console.log($(event.currentTarget));
    console.log($(event.currentTarget).closest('.color-input-wrapper'));
    // Remove the color input wrapper when the remove button is clicked
    $(event.currentTarget).closest('.color-input-wrapper').remove();
  }
}
