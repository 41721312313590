import ValidationHelper from './validation_helper';
import $ from 'jquery';

export default class NewDashboardCardValidationHelper extends ValidationHelper {
  stepValidations(errorMessages) {
    return [
      [],
      [
        // {
        //   type: 'group',
        //   id: '#card_calculation_group_validation_id',
        //   errorMessage: 'Calculation Class must be selected',
        // },
        {
          type: 'group',
          id: '#card_active_record_group_validation_id',
          errorMessage: 'Active Record must be selected',
        },
      ],
      [
        {
          type: 'field',
          id: '#card_title',
          rules: [
            {
              rule: 'required',
              errorMessage: 'Please enter a card title',
            },
          ],
        },
      ],
      [...turboStreamSingleStatValidation],
    ];
  }
}

const turboStreamSingleStatValidator = (value) => {
  const visualisation = $(
    'input[name="card[insight[visualisation]]"]:checked',
  ).val();
  const turboStreamType = $(
    'input[name="card[insight[turbo_stream_type]]"]:checked',
  ).val();

  if (
    visualisation === 'turbo_stream' &&
    turboStreamType === 'single_number_stat'
  ) {
    return value.trim() !== ''; // Make field required if visualisation is 'turbo_stream'
  }

  return true;
};

const turboStreamTwoStatValidator = (value) => {
  const visualisation = $(
    'input[name="card[insight[visualisation]]"]:checked',
  ).val();
  const turboStreamType = $(
    'input[name="card[insight[turbo_stream_type]]"]:checked',
  ).val();

  if (
    visualisation === 'turbo_stream' &&
    turboStreamType === 'two_number_stat'
  ) {
    return value.trim() !== ''; // Make field required if visualisation is 'turbo_stream'
  }

  return true;
};

const turboStreamSingleStatValidation = [
  {
    type: 'field',
    id: '#card_options_primary_key',
    errorMessage: 'Primary Key is required',
    validator: turboStreamSingleStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_primary_stat_template',
    validator: turboStreamSingleStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_secondary_stat_template',
    validator: turboStreamSingleStatValidator,
  },
];

const turboStreamTwoStatValidation = [
  {
    type: 'field',
    id: '#card_options_primary_key_1',
    validator: turboStreamTwoStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_primary_template_1',
    validator: turboStreamTwoStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_secondary_template_1',
    validator: turboStreamTwoStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_primary_key_2',
    validator: turboStreamTwoStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_primary_template_2',
    validator: turboStreamTwoStatValidator,
  },
  {
    type: 'field',
    id: '#card_options_secondary_template_2',
    validator: turboStreamTwoStatValidator,
  },
];
