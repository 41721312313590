import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import maplibregl from 'maplibre-gl';

import { europeCountryNames, europeanUnionNames } from './constants';

const MAP_VIEWS = {
  world: 'world',
  european_union: 'european_union',
  europe: 'europe',
  us_states: 'us_states',
};

const DEFAUT_MAP_VIEWS = MAP_VIEWS.world;

export default class extends Controller {
  static targets = ['container', 'tooltip'];

  static values = {
    data: Array,
    options: { type: Object, default: {} },
    colourScale: { type: Array, default: [] },
    defaultBgColour: String,
  };

  get usaStates() {
    const data = this.dataValue.find((el) => el.alpha_3 === 'USA');

    return data ? data.states : [];
  }

  get currentData() {
    return this.mapView === MAP_VIEWS.us_states
      ? this.usaStates
      : this.dataValue;
  }

  get matchLayerProperty() {
    return this.mapView === MAP_VIEWS.us_states ? 'NAME' : 'ADM0_A3';
  }

  get matchDataProperty() {
    return this.mapView === MAP_VIEWS.us_states ? 'name' : 'country_code';
  }

  get allLayersID() {
    return this.map.getStyle().layers.map((el) => el.id);
  }

  get usStatesMapLayers() {
    return this.allLayersID.filter((id) => id.includes('us-states'));
  }

  get countryFillLayerID() {
    return this.mapView === MAP_VIEWS.us_states
      ? this.usStatesMapLayers.find((l) => l.includes('fill'))
      : 'countries-fill';
  }

  get countryHoverLayerID() {
    return this.mapView === MAP_VIEWS.us_states
      ? this.usStatesMapLayers.find((l) => l.includes('hover'))
      : 'countries-hover';
  }

  connect() {
    this.map = new maplibregl.Map({
      attributionControl: false,
      container: this.containerTarget,
      style: '../../../../map_style/style.json',
      center: [0, 50], // starting position [lng, lat]
      zoom: 1, // starting zoom
      maxZoom: 6,
      minZoom: 0,
      renderWorldCopies: false,
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
    });

    this.map.once('load', this.setupMap.bind(this));

    this.mapView = DEFAUT_MAP_VIEWS;
  }

  setupMap() {
    this.setupMapView();
    this.map.addControl(
      new maplibregl.NavigationControl({
        showCompass: false,
        visualizePitch: false,
      }),
      'top-left',
    );

    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    this.updateLayers();
    this.colourLayers();
  }

  setupMapView() {
    const options = $("input[type=radio][name='map_view']");
    const selectedView = options.filter(':checked').val();

    options.on('change', this.onMapViewChange.bind(this));

    if (selectedView && selectedView !== DEFAUT_MAP_VIEWS) {
      this.mapView = selectedView;
      this.flyTo();
    }
  }

  colourLayers() {
    const fillColours = this.countriesFillColour();

    // by default:
    // [0] - 'match', [1] - ['get', 'ADM0_A3'], [2] - default_colour
    // if the length is 3 - it means that there are no data which match conditions
    // so we don't need to add a colour layer

    if (fillColours.length < 4) return;

    this.map.setPaintProperty(
      this.countryFillLayerID,
      'fill-color',
      fillColours,
    );
  }

  countriesFillColour() {
    const match = ['match', ['get', this.matchLayerProperty]];

    const groups = [...Array(this.colourScaleValue.length)].map(() => []);

    this.currentData.forEach((el) => {
      this.colourScaleValue.forEach((scale, index) => {
        const minValue = scale[0];
        const maxValue = scale[1];

        if (!maxValue && el.data.total >= minValue) {
          groups[index].push(el[this.matchDataProperty]);
        }

        if (
          maxValue &&
          el.data.total >= minValue &&
          el.data.total <= maxValue
        ) {
          groups[index].push(el[this.matchDataProperty]);
        }
      });
    });

    groups.forEach((el, index) => {
      if (el.length > 0) {
        match.push(el);
        match.push(this.colourScaleValue[index][2]);
      }
    });

    match.push(this.defaultBgColourValue);

    return match;
  }

  updateLayers() {
    let visibleLayers = ['countries-fill'];
    let hiddenLayers = this.usStatesMapLayers;
    let countryFilter = [];

    this.map.on('mousemove', this.onHover.bind(this));

    switch (this.mapView) {
      case MAP_VIEWS.world: {
        countryFilter = ['all'];

        break;
      }
      case MAP_VIEWS.us_states: {
        visibleLayers = this.usStatesMapLayers;
        hiddenLayers = ['countries-fill'];
        countryFilter = ['all', ['==', 'ADM0_A3', 'USA']];
        break;
      }
      case MAP_VIEWS.europe: {
        countryFilter = ['all', ['in', 'NAME', ...europeCountryNames]];

        break;
      }
      case MAP_VIEWS.european_union: {
        countryFilter = ['all', ['in', 'NAME', ...europeanUnionNames]];

        break;
      }
    }

    visibleLayers.forEach((id) =>
      this.map.setLayoutProperty(id, 'visibility', 'visible'),
    );
    hiddenLayers.forEach((id) =>
      this.map.setLayoutProperty(id, 'visibility', 'none'),
    );

    this.map.setFilter('countries-fill', countryFilter);
    this.map.setFilter('countries-boundary', countryFilter);
    this.map.setFilter('countries-label', countryFilter);
  }

  onHover(e) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [this.countryFillLayerID],
    });

    const data = features.length
      ? this.currentData.find(
          (el) =>
            el[this.matchDataProperty] ===
            features[0].properties[this.matchLayerProperty],
        )
      : null;

    if (data) {
      this.map.getCanvas().style.cursor = 'pointer';
      this.map.setFilter(this.countryHoverLayerID, [
        '==',
        'NAME',
        features[0].properties.NAME,
      ]);

      $(this.tooltipTarget)
        .children()
        .first()
        .text(`${features[0].properties.NAME}`);
      $(this.tooltipTarget).children().last().text(`${data.data.total}`);

      $(this.tooltipTarget).parent().show();
    } else {
      this.map.setFilter(this.countryHoverLayerID, ['==', 'NAME', '']);
      this.map.getCanvas().style.cursor = '';
      $(this.tooltipTarget).children().text('');
      $(this.tooltipTarget).parent().hide();
    }
  }

  onMapViewChange(e) {
    if (Object.values(MAP_VIEWS).includes(e.target.value)) {
      this.mapView = e.target.value;
      this.flyTo();
    }
  }

  flyTo() {
    let center = [];
    let zoom = [];

    switch (this.mapView) {
      case MAP_VIEWS.world: {
        center = [0, 50];
        zoom = 1;
        break;
      }
      case MAP_VIEWS.us_states: {
        center = [-180, 50];
        zoom = 2.5;
        break;
      }
      case MAP_VIEWS.europe: {
        center = [64, 68];
        zoom = 2;
        break;
      }
      case MAP_VIEWS.european_union: {
        center = [10, 57];
        zoom = 3;
        break;
      }
      default: {
        center = [0, 0];
        zoom = 0;
      }
    }

    this.map.flyTo({
      center,
      zoom,
      speed: 1.2,
      curve: 1,
      easing(t) {
        return t;
      },
    });

    const self = this;

    setTimeout(() => {
      self.updateLayers();
      self.colourLayers();
    }, 500);
  }
}
