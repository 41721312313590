import $ from 'jquery';

export default class ValidationHelper {
  toggleExpandedValidation(validator, conditionValidation, value) {
    /*
    1) Look if clickedId has sub-fields under inputId in fields
    2) If yes, add sub-fields to validator
    3) Else, check if other options are open and close them
    */

    if (conditionValidation[value]) {
      this.addNextStepValidation(
        validator,
        conditionValidation[value].sub_fields,
      );
    } else {
      Object.values(conditionValidation).forEach((option) => {
        option.sub_fields.forEach((subField) => {
          if (subField.type === 'field') {
            const isInValidator = Object.values(validator.fields).find(
              (f) => f.elem.id === subField.id.substring(1),
            );

            if (isInValidator) {
              validator.removeField(subField.id);
            }
          } else if (subField.type === 'group') {
            const isInValidator = Object.values(validator.groupFields).find(
              (f) => f.groupElem.id === subField.id.substring(1),
            );

            if (isInValidator) {
              validator.removeGroup(subField.id);
            }
          }
        });
      });
    }
  }

  addNextStepValidation(validator, fields) {
    fields?.forEach((validation) => {
      switch (validation['type']) {
        case 'group': {
          validator.addRequiredGroup(
            validation['id'],
            validation['errorMessage'],
            validation['config'],
          );

          if (validation['condition_validation']) {
            const conditionValidation = validation['condition_validation'];

            $(validation['id'])
              .find('input')
              .on('change', (event) => {
                const value = $(event.target).val();

                this.toggleExpandedValidation(
                  validator,
                  conditionValidation,
                  value,
                );
              });
          }
          break;
        }
        case 'field': {
          if (validation['validator']) {
            validator.addField(validation['id'], [
              {
                validator: validation['validator'],
                errorMessage: validation['errorMessage'],
              },
            ]);
          } else {
            validator.addField(
              validation['id'],
              validation['rules'].reduce((acc, cur) => {
                const rule = cur.value
                  ? { ...cur, value: new RegExp(cur.value) }
                  : cur;

                return [...acc, rule];
              }, []),
              validation['config'],
            );
          }
          break;
        }
      }
    });
  }

  removePrevStepValidation(validator, fields) {
    fields?.forEach((validation) => {
      switch (validation['type']) {
        case 'group': {
          validator.removeGroup(validation['id']);
          break;
        }
        case 'field': {
          validator.removeField(validation['id']);
          break;
        }
      }
    });
  }

  showErrors(validator) {
    const errors = Object.values(validator.fields)
      .filter((f) => f.isValid === false)
      .reduce((acc, cur) => {
        const fieldId = cur.elem.id;
        return { ...acc, [`#${fieldId}`]: cur.errorMessage };
      }, {});
    validator.showErrors(errors);
  }
}
