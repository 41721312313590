import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form', 'bg', 'modal', 'content', 'wizard', 'modalContent'];

  static values = {
    hideOnSubmit: { type: Boolean, default: true },
  };

  hideModal() {
    this.start = null;

    requestAnimationFrame(this.hideAnimation.bind(this));
  }

  hideAnimation(timeStamp) {
    if (!this.start) {
      this.start = timeStamp;
    }

    const elapsed = timeStamp - this.start;

    const modal = $(this.element).find('.modal-window');

    if ($(modal).hasClass('open')) {
      $(modal).removeClass('open');
      $('body').removeClass('fixed-full-screen-h');
    }

    if (elapsed < 200) {
      requestAnimationFrame(this.hideAnimation.bind(this));
    } else {
      $(this.contentTarget).empty();
      $(this.element).hide();
    }
  }

  submitAndHideModal(e) {
    e.preventDefault();
    const form = this.hasFormTarget
      ? this.formTarget
      : $(this.contentTarget).find('form')[0];

    if (form && form.reportValidity()) {
      form.requestSubmit();
    }
  }

  clearForm() {
    this.formTarget.reset();
  }

  open() {
    if ($(this.element).is(':hidden') || this.hasWizardTarget) {
      $(this.element).show();

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          $(this.element).find('.modal-window').addClass('open');
          $('body').addClass('fixed-full-screen-h');
        });
      });

      if (this.hasFormTarget) {
        this.addSubmitFormListener();
      }
    }
  }

  // Hide the modal if the submission was successful
  addSubmitFormListener() {
    this.formTarget.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.hideModal();
      }
    });
  }

  toggleHeight() {
    const minimized = this.modalContentTarget.dataset.minimized;
    const modal = $(this.element).find('.modal-window');

    if (parseInt(minimized) === 1) {
      this.modalContentTarget.dataset.minimized = 0;
      $(modal).css({ transform: 'translate(0, 0)' });

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          $(this.modalContentTarget).css('height', this.modalContentHeight);
          $(this.bgTarget).removeClass('!backdrop-blur-none opacity-0');

          $('body').addClass('fixed-full-screen-h');
        });
      });
    } else {
      this.modalContentTarget.dataset.minimized = 1;
      $(modal).css({ transform: 'translate(0, 1rem)' });

      this.minimizeModal();
    }
  }

  minimizeModal() {
    const clientRect = this.modalContentTarget.getBoundingClientRect();
    this.modalContentHeight = clientRect.height;
    $(this.modalContentTarget).css('height', clientRect.height);

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        $(this.modalContentTarget).css('height', 0);
        $(this.bgTarget).addClass('!backdrop-blur-none opacity-0');

        $('body').removeClass('fixed-full-screen-h');
      });
    });
  }
}
