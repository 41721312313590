import consumer from './consumer';
import $ from 'jquery';

consumer.subscriptions.create('NotificationsChannel', {
  connected() {
    console.log('NotificationsChannel - connected');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log('NotificationsChannel - disconnected');

    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('N-data', data);
    // Called when there's incoming data on the websocket for this channel
    const indicator = $('#unread-notifications-indicator');

    indicator.show();

    const textValue = indicator.text();
    const intValue = textValue ? parseInt(textValue) : 0;

    indicator.text(intValue + 1);
  },
});
