// app/javascript/controllers/community_builder_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'permissionSelect',
    'subPurposeSelect',
    'submitButton',
    'grantorSearchForm',
    'grantorCheckbox',
    'selectAllCheckbox',
    'chartContainer'  // Add this new target
  ];

  connect() {
    console.log('Community Builder controller connected');
    this.validatePermissions();
    // Initialize debounce timer
    this.debounceTimer = null;
    this.DEBOUNCE_DELAY = 300; // milliseconds
  }

  validatePermissions() {
    const selectedPermissions = Array.from(
      this.permissionSelectTarget.querySelectorAll('input[type="checkbox"]:checked')
    ).filter(checkbox => checkbox.id !== 'select_all_permissions');

    const isValid = selectedPermissions.length > 0;
    this.submitButtonTarget.disabled = !isValid;
    
    // Update button styling
    if (isValid) {
      this.submitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed');
      this.submitButtonTarget.classList.add('bg-blue-500', 'hover:bg-blue-600', 'text-white');
    } else {
      this.submitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed');
      this.submitButtonTarget.classList.remove('bg-blue-500', 'hover:bg-blue-600', 'text-white');
    }
  }

  updatePermissionSelection() {
    this.validatePermissions();
    this.updateSubPurposeOptionsAndGrantorList();
  }

  updateSubPurposeOptionsAndGrantorList() {
    const selectedPermissionIds = Array.from(
      this.permissionSelectTarget.querySelectorAll('input[type="checkbox"]:checked')
    )
      .filter(checkbox => checkbox.id !== 'select_all_permissions')
      .map(checkbox => checkbox.value);

    // Fetch sub-purposes
    fetch(
      `/sub_purposes?permission_ids=${selectedPermissionIds.join(',')}`,
      {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
          'X-Requested-With': 'XMLHttpRequest',
        },
        credentials: 'same-origin',
      },
    )
      .then((response) => response.text())
      .then((html) => {
        //console.log('DEBUG - Received Turbo Stream:', html);
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const turboFrame = doc.querySelector('turbo-frame');
        if (turboFrame) {
          const existingFrame = document.getElementById(turboFrame.id);
          if (existingFrame) {
            existingFrame.innerHTML = turboFrame.innerHTML;
          } else {
            console.log('Frame not found in document');
          }
        } else {
          console.log('No matching turbo-frame found in response');
        }
        // Sub Purposes have been processed now update the Grantor listing
        this.fetchGrantors();
      })
      .catch((error) => console.error('Error fetching sub-purposes:', error));
  }

  fetchGrantors(event) {
    // If event exists, prevent default behavior
    if (event) {
      event.preventDefault();
    }
    
    const selectedPermissions = Array.from(
      this.permissionSelectTarget.querySelectorAll('input[type="checkbox"]:checked')
    ).filter(checkbox => checkbox.id !== 'select_all_permissions').map(checkbox => checkbox.value);

    const selectedSubPurposes = Array.from(
      this.subPurposeSelectTarget.querySelectorAll('input[type="checkbox"]:checked')
    ).map(checkbox => checkbox.value);

    const form = this.grantorSearchFormTarget;
    const formData = new FormData(form);

    // Add permission and sub-purpose IDs to formData
    formData.append('permission_ids', selectedPermissions.join(','));
    formData.append('sub_purpose_ids', selectedSubPurposes.join(','));

    // Include all form fields from the grantor search form
    const grantorSearchForm = this.grantorSearchFormTarget;
    new FormData(grantorSearchForm).forEach((value, key) => {
      formData.append(key, value);
    });

    // Fire off chart update without awaiting - it will run in the background
    //this.updateChart(formData).catch(error => 
    //   console.error('Background chart update failed:', error)
    //);

    // Grantor list update
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
      },
      credentials: 'same-origin',
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((error) => console.error('Error fetching grantors:', error));
  }


  // Add this method to handle permission checkbox changes
  handlePermissionChange() {
    this.validatePermissions();
    
    // Clear any existing timer
    clearTimeout(this.debounceTimer);
    
    // Set new timer
    this.debounceTimer = setTimeout(() => {
      this.updateSubPurposeOptionsAndGrantorList();
    }, this.DEBOUNCE_DELAY);
  }
  
  submitForm(event) {
    // Check for selected permissions using checkboxes
    const selectedPermissions = Array.from(
      this.permissionSelectTarget.querySelectorAll('input[type="checkbox"]:checked')
    ).filter(checkbox => checkbox.id !== 'select_all_permissions');

    if (selectedPermissions.length === 0) {
      event.preventDefault();
      alert('Please select at least one permission before submitting.');
      return;
    }

    // Get the form that's being submitted
    const form = event.target.closest('form');
    
    // Collect filter criteria from the search form
    const filterForm = this.grantorSearchFormTarget;
    const formData = new FormData(filterForm);
    
    // Create a structured filter object that matches the ransack params structure
    const filterData = {
      q: {
        by_country_code: formData.getAll('q[by_country_code][]'),
        by_industry: formData.getAll('q[by_industry][]'),
        by_company: formData.getAll('q[by_company][]'),
        by_job_title: formData.getAll('q[by_job_title][]'),
        collection_source_id_in: formData.getAll('q[collection_source_id_in][]')
      }
    };

    // Create a hidden input for filter criteria
    const filterInput = document.createElement('input');
    filterInput.type = 'hidden';
    filterInput.name = 'community[filter_criteria]';
    filterInput.value = JSON.stringify(filterData);

    // Add selected grantor IDs if any
    const selectedGrantorIds = this.grantorCheckboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    
    if (selectedGrantorIds.length > 0) {
      // Create separate hidden input for each grantor ID
      selectedGrantorIds.forEach(grantorId => {
        const grantorInput = document.createElement('input');
        grantorInput.type = 'hidden';
        grantorInput.name = 'community[grantor_ids][]';
        grantorInput.value = grantorId;
        form.appendChild(grantorInput);
      });
    }

    // Add filter criteria to form
    form.appendChild(filterInput);
  }

  toggleAllGrantors(event) {
    const isChecked = event.target.checked;
    this.grantorCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
      this.toggleGrantor({ target: checkbox });
    });
  }

  toggleGrantor(event) {
    // Your existing logic for toggling a single grantor
    this.updateSelectAllCheckbox();
  }

  async updateChart(formData) {
    try {
      const response = await fetch(`${this.grantorSearchFormTarget.action}.json`, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        credentials: 'same-origin',
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
      
      const chartData = await response.json();
      // Get chart directly by its ID if you know it
      const chartElement = this.chartContainerTarget.querySelector("[data-chartkick]");
      if (!chartElement) {
        console.warn('Chart element not found');
        return;
      }
      const chart = Chartkick.charts[chartElement.id];
      if (chart) {
        chart.updateData(chartData);
      } else {
        console.warn('Chart instance not found');
      }
    } catch (error) {
      console.error('Error updating chart:', error);
    }
  }
  
  updateSubPurposeSelection() {
    this.fetchGrantors();
  }
  
  updateSelectAllCheckbox() {
    if (this.hasSelectAllCheckboxTarget) {
      const allChecked = this.grantorCheckboxTargets.every(
        (checkbox) => checkbox.checked,
      );
      this.selectAllCheckboxTarget.checked = allChecked;
    }
  }

}
