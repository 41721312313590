import $ from 'jquery';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['stepper'];

  startNavigation(e) {
    $(this.stepperTargets).filter('[data-step="0"]').hide();

    const nextSection = $(this.stepperTargets).filter('[data-step="1"]');
    let currentSection = nextSection[0];

    if (nextSection.length > 1) {
      const value = e.target.value;

      $(nextSection).filter(`div[data-option-value='${value}']`).show();
      $(nextSection).filter(`div[data-option-value!='${value}']`).hide();

      currentSection = $(nextSection).filter(
        `div[data-option-value='${value}']`,
      )[0];
    } else {
      $(nextSection).show();
    }

    if ($(currentSection).data('last-step')) {
      $('#action-buttons-section').show();
    } else {
      $('#action-buttons-section').hide();
    }
  }

  goBack(e) {
    const step = $(e.target).data('step') || 0;

    $(this.stepperTargets).hide();
    $(this.stepperTargets).filter(`[data-step="${step}"]`).show();

    if (parseInt(step) === 0) {
      $('#action-buttons-section').show();
    }
  }
}
