import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "select", "infoContainer", "info" ]

  connect() {
    console.log('Community INFO controller connected');
  }

  fetchInfo() {
    const communityId = this.selectTarget.value
    if (communityId) {
      fetch(`/communities/${communityId}`)
        .then(response => response.json())
        .then(data => {
          this.displayInfo(data)
          this.togglePanel(true)
        })
        .catch(error => {
          console.error('Error fetching community info:', error);
          this.infoTarget.innerHTML = '<p>Error loading Community information. Please try again.</p>';
        });
    } else {
      this.infoTarget.innerHTML = ""
      this.togglePanel(false)
    }
  }

  displayInfo(data) {
    const community = data.community;
    console.log(community);
    
    this.infoTarget.innerHTML = `
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h3 class="text-2xl font-bold text-indigo-700 mb-4">${community.name}</h3>
        <p class="text-gray-700 mb-3">${community.description}</p>
                 <p class="text-gray-700 mb-3">Members: ${community.size}</p>
        <p class="text-sm text-gray-500 mb-6">Created: ${new Date(community.created_at).toLocaleDateString()}</p>
        
        <h4 class="text-lg font-semibold text-indigo-600 mb-3">Permissions:</h4>
        <ul class="space-y-3">
          ${community.permissions.map(permission => `
            <li class="bg-indigo-50 p-3 rounded-md">
              <p class="text-indigo-700 font-medium">${permission.short_description}</p>
              ${permission.regulation ? `
                <p class="text-sm text-indigo-500 mt-1">
                  <span class="font-medium">Regulation:</span> ${permission.regulation.short_name}
                </p>
              ` : ''}
            </li>
          `).join('')}
        </ul>
      </div>
    `;
  
    // Adjust the width of the info container
    const infoContainer = this.infoContainerTarget;
    infoContainer.classList.remove('hidden');
    infoContainer.classList.add('col-span-2', 'mt-4');
  }

  togglePanel(open) {
    const row = this.infoContainerTarget.closest('[data-target="community-info"]')
    if (open && row.classList.contains('hidden')) {
      row.classList.remove('hidden')
    } else if (!open && !row.classList.contains('hidden')) {
      row.classList.add('hidden')
    }
    
    // Update arrow if it exists
    const arrow = row.querySelector('[data-collapsable-table-target="arrow"]')
    if (arrow) {
      arrow.querySelector('span').textContent = open ? '▼' : '▶'
    }
  }

}

 