import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';
import $ from 'jquery';
import { getValidationHelper } from '../src/helpers/validation_helper_factory';

export default class extends Controller {
  static targets = ['stepContent', 'stepIndicator', 'next', 'prev', 'submit'];
  static classes = ['activeStep', 'completedStep'];
  static values = {
    step: { type: Number, default: 0 },
    validationStep: { type: Number, default: -1 },
    stepsCount: Number,
    validationHelper: String,
    errorMessages: { type: Object, default: {} },
  };

  connect() {
    this.nextButtonPressed = false;

    this.validate = new JustValidate('#multistep_form', {
      validateBeforeSubmitting: true,
      errorFieldCssClass: 'error-field',
      errorLabelCssClass: 'error-label',
    });
    this.helper = getValidationHelper(this.validationHelperValue);

    this.stepsValidation = this.helper.stepValidations(this.errorMessagesValue);

    $(this.nextTarget).on('click', this.goNext.bind(this));
    $(this.prevTarget).on('click', this.goBack.bind(this));
    $(this.submitTarget).on('click', this.submitForm.bind(this));

    this.validate.onValidate(({ isValid, isSubmitted, fields, groups }) => {
      const isDisabled = !isValid && this.nextButtonPressed;
      $(this.submitTarget).attr('disabled', isDisabled);
      // $(this.nextTarget).attr('disabled', isDisabled);

      Object.values(groups).forEach((group) => {
        if (!group.touched && !isSubmitted) return;

        if (group.isValid) {
          $(group.groupElem)
            .find('button.selector')
            .first()
            .removeClass('invalid');
        } else {
          $(group.groupElem)
            .find('button.selector')
            .first()
            .addClass('invalid');
        }
      });
    });
  }

  stepValueChanged(val) {
    this.validationStepValue = val;

    this.showStepContent();
    this.checkButtonsVisibility();

    if (val === this.stepsCountValue - 1) {
      this.dispatch('lastStep');
    }

    this.element.dispatchEvent(
      new CustomEvent('wizard-form-step-changed', {
        detail: { step: val },
        bubbles: true,
      }),
    );
  }

  validationStepValueChanged(val, prev) {
    if (this.stepsValidation.length > 0) {
      if (val > prev) {
        this.helper.addNextStepValidation(
          this.validate,
          this.stepsValidation[val],
        );
      } else {
        this.helper.removePrevStepValidation(
          this.validate,
          this.stepsValidation[prev],
        );
      }
    }
  }

  goBack() {
    this.stepValue = this.stepValue - 1;
  }

  goNext() {
    const self = this;
    this.nextButtonPressed = true;

    this.validate.revalidate().then((isValid) => {
      if (isValid) {
        self.stepValue = self.stepValue + 1;
      } else {
        this.helper.showErrors(self.validate);
      }
    });
  }

  goToStep(event) {
    const step = event.params.step;
    this.stepValue = step;
  }

  submitForm() {
    this.validate.destroy();

    setTimeout(() => {
      document.getElementById('multistep_form').requestSubmit();
    }, 100);
  }

  checkButtonsVisibility() {
    $(this.submitTarget).css(
      'display',
      this.stepValue + 1 === this.stepsCountValue ? 'block' : 'none',
    );
    $(this.nextTarget).css(
      'display',
      this.stepValue + 1 !== this.stepsCountValue ? 'block' : 'none',
    );
    $(this.prevTarget).css('display', this.stepValue > 0 ? 'block' : 'none');
  }

  showStepContent() {
    this.stepContentTargets.forEach((stepContent, index) => {
      if (index === this.stepValue) {
        $(stepContent).show();
      } else {
        $(stepContent).hide();
      }
    });

    this.updateStepIndicatorStyles();
  }

  updateStepIndicatorStyles() {
    this.stepIndicatorTargets.forEach((stepEl, index) => {
      if (this.hasActiveStepClass) {
        if (index === this.stepValue) {
          stepEl.classList.add(...this.activeStepClasses);
        } else {
          stepEl?.classList?.remove(...this.activeStepClasses);
        }
      }

      if (this.hasCompletedStepClass) {
        if (index < this.stepValue) {
          stepEl?.classList?.add(...this.completedStepClasses);
        } else {
          stepEl?.classList?.remove(...this.completedStepClasses);
        }
      }
    });
  }
}
